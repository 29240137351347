<template>
  <aside class="relative z-40">
    <div v-if="dialog" class="fixed inset-0 bg-black bg-opacity-50" @click="close()"></div>
    <div class="fixed top-0 left-72 m-2 transform duration-300 transition-all ease-in-out" :class="dialog ? 'visible translate-x-0' : 'invisible -translate-x-72'">
      <font-awesome-icon icon="fa-solid fa-xmark" class="text-white" @click="close()" />
    </div>
    <div class="fixed inset-0 md:relative md:flex flex-col gap-4 w-72 overflow-auto bg-white md:bg-transparent transform duration-300 transition-all ease-in-out" :class="[dialog ? 'visible translate-x-0' : 'invisible -translate-x-72 md:visible md:translate-x-0']">
      <!-- <div class="flex flex-col rounded-lg bg-white p-4 font-semibold block md:hidden">
        <SearchBarNew class="block md:hidden" :filter="filter" @handleSearch="handleSearch"
          @handleWilayah="handleWilayah" />
      </div> -->
      <div class="rounded-lg md:bg-white py-4 md:px-6 block">
        <div class="flex flex-wrap md:flex-nowrap md:justify-between justify-start items-center">
          <div
            class="flex flex-row items-center justify-between md:px-5 md:py-2 py-0.5 px-2.5 border border-primary rounded-full w-full md:w-fit">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
              class="w-5 h-5 pr-1 text-primary">
              <path fill-rule="evenodd"
                d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd" />
            </svg>
            <select class="text-primary md:w-48 w-full font-semibold" v-model="filter.country"
              @change="$emit('handleWilayah')">
              <option value="ID">Indonesia</option>
              <!-- <option value="MY">Malaysia</option> -->
            </select>
          </div>
        </div>
      </div>
      <!-- <div class="flex flex-col rounded-lg bg-white p-4 font-semibold">
        <div class="flex flex-row justify-between items-center pb-4 border-b-2 border-grey-1">
          <p class="px-2">Trip Type</p>
        </div>
        <div class="flex flex-col gap-3 pt-2 px-2">
          <div class="flex flex-row gap-2">
            <input type="radio" id="trip_type_safari" name="trip_type" class="cursor-pointer" value="safari"
              v-model="filter.trip_type" />
            <label for="trip_type_safari" class="cursor-pointer">Safari</label>
          </div>
          <div class="flex flex-row gap-2" v-if="filter.menu == '0' || filter.menu == '1'">
            <input type="radio" id="trip_type_rental" name="trip_type" class="cursor-pointer" value="rental"
              v-model="filter.trip_type" @click="$router.push({ name: 'Rental' })" />
            <label for="trip_type_rental" class="cursor-pointer">Rental</label>
          </div>
        </div>
      </div> -->
<!--       
      <div class="flex flex-col rounded-lg bg-white p-4 font-semibold">
        <div class="flex items-center" @click="toggleCategoryAccordion"
          :class="[showAccordion.category ? 'pb-4 border-b-2 border-grey-1' : 'pb-0 border-none']">
          <div class="flex flex-row justify-between items-center w-full px-2">
            <p>Category</p>
            <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.category" />
            <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
          </div>
        </div>
        <div class="flex flex-col gap-3 pt-2 px-2" v-if="showAccordion.category">
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row gap-2">
              <input type="radio" id="category_all" value="0" name="category" class="cursor-pointer"
                v-model="filter.menu" @click="toggleAllAccordion"/>
              <label for="category_all" class="cursor-pointer">All</label>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2">
                <input type="radio" id="water" value="1" name="category" class="cursor-pointer" v-model="filter.menu"
                  @click="toggleWaterAccordion" />
                <label for="water" class="cursor-pointer">Watersport</label>
              </div>
              <div>
                <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.water" />
                <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
              </div>
            </div>
            <div class="flex flex-col ml-4 mt-2 gap-2" v-if="showAccordion.water">
              <div class="flex flex-row gap-2" v-for="(submenu, submenuidx) in listSubMenu" :key="submenuidx">
                <input type="radio" :id="submenu.menu_aktivitas" :value="submenu.menu_aktivitas" name="default-radio"
                  class="cursor-pointer" v-model="filter.submenu" @click="$emit('handleFilterSubmenu', submenu)" />
                <label class="font-normal cursor-pointer" :for="submenu.menu_aktivitas">
                  {{ submenu.menu_aktivitas }}
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2">
                <input type="radio" id="category_land" value="2" name="category" class="cursor-pointer"
                  v-model="filter.menu" @click="toggleLandAccordion" />
                <label for="category_land" class="cursor-pointer">Land Adventure</label>
              </div>
              <div>
                <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.land" />
                <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
              </div>
            </div>
            <div class="flex flex-col ml-4 mt-2 gap-2" v-if="showAccordion.land">
              <div class="flex flex-row gap-2" v-for="(submenu, submenuidx) in listSubMenu" :key="submenuidx">
                <input type="radio" :id="submenu.menu_aktivitas" :value="submenu.menu_aktivitas" name="default-radio"
                  class="cursor-pointer" v-model="filter.submenu" @click="$emit('handleFilterSubmenu', submenu)" />
                <label class="font-normal cursor-pointer" :for="submenu.menu_aktivitas">
                  {{ submenu.menu_aktivitas }}
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2">
                <input type="radio" id="category_sky" value="3" name="category" class="cursor-pointer"
                  v-model="filter.menu" @click="toggleSkyAccordion" />
                <label for="category_sky" class="cursor-pointer">Sky Adventure</label>
              </div>
              <div>
                <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.sky" />
                <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
              </div>
            </div>
            <div class="flex flex-col ml-4 mt-2 gap-2" v-if="showAccordion.sky">
              <div class="flex flex-row gap-2" v-for="(submenu, submenuidx) in listSubMenu" :key="submenuidx">
                <input type="radio" :id="submenu.menu_aktivitas" :value="submenu.menu_aktivitas" name="default-radio"
                  class="cursor-pointer" v-model="filter.submenu" @click="$emit('handleFilterSubmenu', submenu)" />
                <label class="font-normal cursor-pointer" :for="submenu.menu_aktivitas">
                  {{ submenu.menu_aktivitas }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      
      <div class="flex flex-col rounded-lg bg-white p-4 font-semibold">
        <div class="flex items-center" @click="toggleCategoryAccordion"
          :class="[showAccordion.category ? 'pb-4 border-b-2 border-grey-1' : 'pb-0 border-none']">
          <div class="flex flex-row justify-between items-center w-full px-2">
            <p>Category</p>
            <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.category" />
            <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
          </div>
        </div>
        <div class="flex flex-col gap-3 pt-2 px-2" v-if="showAccordion.category">
          <div class="flex flex-row justify-between items-center">
            <div class="flex flex-row gap-2">
              <input type="radio" id="category_all" value="0" name="category" class="cursor-pointer"
                v-model="filter.menu" @click="toggleAllAccordion"/>
              <label for="category_all" class="cursor-pointer">All</label>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2">
                <input type="radio" id="water" value="1" name="category" class="cursor-pointer" v-model="filter.menu"
                  @click="toggleWaterAccordion" />
                <label for="water" class="cursor-pointer">Seadoo Safari</label>
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-row justify-between items-center">
              <div class="flex flex-row gap-2">
                <input type="radio" id="category_land" value="2" name="category" class="cursor-pointer"
                  v-model="filter.menu" @click="toggleLandAccordion" />
                <label for="category_land" class="cursor-pointer">Canam Safari</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col rounded-lg bg-white p-4 font-semibold">
        <div class="flex items-center" @click="toggleDestinationAccordion"
          :class="[showAccordion.destination ? 'pb-4 border-b-2 border-grey-1' : 'pb-0 border-none']">
          <div class="flex flex-row justify-between items-center w-full px-2">
            <p>Destination</p>
            <font-awesome-icon icon="fa-solid fa-chevron-up" class="text-primary" v-if="showAccordion.destination" />
            <font-awesome-icon icon="fa-solid fa-chevron-down" class="text-primary" v-else />
          </div>
        </div>
        <div class="flex flex-col gap-3 pt-2 px-2" v-if="showAccordion.destination">
          <div class="overflow-hidden" :class="{'h-44': !destinationShowAll}">
            <div class="flex flex-row justify-between items-center" v-if="filter.country == 'ID'">
              <div class="flex flex-row gap-2">
                <input type="radio" value="" id="All" name="id_wilayah"
                  class="cursor-pointer" v-model="filter.idWilayah" @click="$emit('changeFilter', {nama_wilayah: ''})" />
                <label for="All" class="cursor-pointer">All</label>
              </div>
            </div>
            <div class="flex flex-row justify-between items-center" v-for="(wilayah, wilayahidx) in listWilayah"
              :key="wilayahidx">
              <div class="flex flex-row gap-2">
                <input type="radio" :id="wilayah.nama_wilayah" :value="wilayah.nama_wilayah" name="id_wilayah"
                  class="cursor-pointer" v-model="filter.idWilayah" @click="$emit('changeFilter', wilayah)" />
                <label :for="wilayah.nama_wilayah" class="cursor-pointer">{{ wilayah.nama_wilayah }}</label>
              </div>
            </div>
          </div>
          <button
          class="flex justify-center items-center border border-grey-2 rounded-xl font-semibold h-9 hover:border-none hover:bg-primary hover:text-white" @click="destinationShowAll = !destinationShowAll;"
        >
          <span v-if="destinationShowAll == false">SHOW ALL</span>
          <span v-else>SHOW LESS</span>
        </button>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
import SearchBarNew from "../components/SearchBarNew.vue";
export default {
  components: {
    SearchBarNew,
  },
  props: {
    listWilayah: {
      type: Array,
      required: true,
    },
    listSubMenu: {
      type: Array,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAccordion: {
        category: true,
        water: false,
        land: false,
        sky: false,
        destination: true,
      },
      destinationShowAll: false,
      dialog: false,
    };
  },
  created() {
    if (this.filter.menu == '1') {
      this.showAccordion.water = true;
    } else if (this.filter.menu == '2') {
      this.showAccordion.land = true;
    } else if (this.filter.menu == '3') {
      this.showAccordion.sky = true;
    } else {
      this.showAccordion.water = false;
      this.showAccordion.land = false;
      this.showAccordion.sky = false;
    }
  },
  methods: {
    async handleSearch() {
      this.$emit('handleSearch');
    },
    async handleWilayah() {
      this.$emit('handleWilayah');
    },
    toggleCategoryAccordion() {
      this.showAccordion.category = !this.showAccordion.category;
      this.showAccordion.water = false;
      this.showAccordion.land = false;
      this.showAccordion.sky = false;
    },
    toggleDestinationAccordion() {
      this.showAccordion.destination = !this.showAccordion.destination;
    },
    toggleAllAccordion() {
      this.showAccordion.water = false;
      this.showAccordion.land = false;
      this.showAccordion.sky = false;
      this.$emit('handleMenuChange', 0);
    },
    toggleWaterAccordion() {
      this.showAccordion.water = !this.showAccordion.water;
      this.showAccordion.land = false;
      this.showAccordion.sky = false;
      this.$emit('handleMenuChange', 1);
    },
    toggleLandAccordion() {
      this.showAccordion.land = !this.showAccordion.land;
      this.showAccordion.water = false;
      this.showAccordion.sky = false;
      this.$emit('handleMenuChange', 2);
    },
    toggleSkyAccordion() {
      this.showAccordion.sky = !this.showAccordion.sky;
      this.showAccordion.water = false;
      this.showAccordion.land = false;
      this.$emit('handleMenuChange', 3);
    },
    close(){
      this.dialog = false
    },
    open(){
      this.dialog = true
    }
  },
};
</script>
