<template>
  <div class="rounded-full md:shadow-xl md:bg-white py-4 md:px-6 mt-4">
    <div class="flex flex-wrap md:flex-nowrap md:justify-between justify-start items-center">
      <div
        class="flex flex-row items-center justify-between md:px-5 md:py-2 py-0.5 px-2.5 border border-primary rounded-full w-full md:w-fit">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
          class="w-5 h-5 pr-1 text-primary">
          <path fill-rule="evenodd"
            d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
            clip-rule="evenodd" />
        </svg>
        <select class="text-primary md:w-48 w-full font-semibold" v-model="filter.country"
          @change="$emit('handleWilayah')">
          <option value="ID">Indonesia</option>
          <option value="MY">Malaysia</option>
        </select>
      </div>
      <div
        class="flex md:h-12 h-9 w-full items-center rounded-full overflow-hidden bg-white border border-e2 md:mx-5 my-3 md:my-0">
        <button
          class="flex justify-center items-center md:w-14 w-12 h-full bg-transparent hover:bg-e2 transition-all duration-500">
          <div class="flex justify-items-center">
            <svg class="md:w-8 md:h-8 w-6 h-6 text-black font-bold" fill="currentColor"
              xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 30 30">
              <path
                d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z">
              </path>
            </svg>
          </div>
        </button>
        <input placeholder="Search trip packages" maximum-scale="1"
          class="sm:px-2 py-5 w-full bg-transparent outline-none" name="search" autocomplete="off" type="text"
          v-model="filter.search" @keypress.enter="$emit('handleSearch')" />
      </div>
      <div class="w-full md:w-fit flex justify-end">
        <button
          class="md:h-12 h-9 md:px-12 px-5 flex justify-center items-center rounded-full bg-primary text-white text-lg font-semibold hover:bg-primary-darker"
          @click="$emit('handleSearch')">
          Search
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
};
</script>
