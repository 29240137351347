<template>
  <div class="w-full flex flex-col rounded-xl shadow-lg bg-white">
    <div class="md:h-44 h-28" @click="$emit('selectPackage', $props.data)">
      <img :src="$props.data.galery_package" alt="Jetski" class="object-fill p-2 h-full w-full rounded-t-xl" />
    </div>
    <div class="h-full w-full p-2 md:p-4 flex flex-col gap-2 justify-between">
      <div class="flex flex-row justify-between items-center">
        <p
          v-if="$props.data.level === '3'"
          class="md:font-semibold font-medium md:text-sm text-2xs bg-primary md:px-6 px-4 py-1 rounded-md text-white">
          Advanced
        </p>
        <p
          v-else-if="$props.data.level === '2'"
          class="md:font-semibold font-medium md:text-sm text-2xs bg-yellow-f6 md:px-6 px-4 py-1 rounded-md text-black">
          Intermediate
        </p>
        <p
          v-else
          class="md:font-semibold font-medium md:text-sm text-2xs bg-green-2 md:px-6 px-4 py-1 rounded-md text-black">
          Beginner
        </p>
        <font-awesome-icon
          icon="fa-solid fa-bookmark"
          class="text-primary md:w-6 md:h-6 h-4 w-4 cursor-pointer"
          v-if="$props.data.idw != null && $props.data.idw != '0'"
          @click="$emit('toggleWishlist', $props.data)" />
        <font-awesome-icon
          icon="fa-solid fa-bookmark"
          class="md:w-6 md:h-6 h-4 w-4 text-gray-400 cursor-pointer"
          @click="$emit('toggleWishlist', $props.data)"
          v-else />
      </div>
      <div
        class="flex gap-2 justify-between my-2 items-center"
        @click="$router.push({ name: 'ListPaketToko', params: { slug: $props.data.slug_url } })">
        <div class="flex gap-2">
          <font-awesome-icon icon="fa-solid fa-location-dot" class="h-3 w-3 md:h-5 md:w-5" />
          <p class="text-2xs md:text-xs md:h-5">{{ $props.data.nama_toko }} - {{ $props.data.nama_kota }}</p>
        </div>
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </div>
      <div class="h-8 md:h-12" @click="$emit('selectPackage', $props.data)">
        <p class="text-xs md:text-base 2xl:text-lg font-semibold line-clamp-2">
          {{ $props.data.nama }}
        </p>
      </div>
      <div class="flex flex-col gap-2" @click="$emit('selectPackage', $props.data)">
        <div class="flex flex-row items-center">
          <font-awesome-icon
            icon="fa-solid fa-star"
            class="text-yellow h-4 w-4 mr-1"
            size="sm"
            v-if="$props.data.review_value > 0" />
          <font-awesome-icon v-else icon="fa-regular fa-star" class="text-yellow h-4 w-4 mr-1" />
          <p class="text-primary md:text-xs text-2xs font-medium md:mr-6 mr-3">
            {{ $props.data.review_value }}
          </p>
          <p class="text-grey-2 md:text-xs text-2xs font-medium">({{ $props.data.review_total }} reviews)</p>
        </div>
        <div class="flex flex-col h-5 md:h-10">
          <p
            class="flex justify-end items-center font-medium text-2xs md:text-sm text-grey-2 line-through"
            v-if="$props.data.normal_price != $props.data.start_price">
            {{ $props.data.normal_price | toCurrency }}
          </p>
          <div class="flex flex-row justify-between items-center">
            <p class="text-2xs font-medium">Starting from</p>
            <p class="font-bold text-2xs md:text-sm md:text-lg text-primary">
              {{ $props.data.start_price | toCurrency }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {},
  data: () => ({
    isBookmarked: true,
  }),
  computed: {},
  methods: {
    onBookmarked() {
      this.isBookmarked = !this.isBookmarked;
    },
  },
};
</script>
